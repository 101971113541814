<template>
    <div class="my_coupon">
        <van-nav-bar title="我的优惠券" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="coupon_list" v-if="couponList.length">
            <div class="coupon_box" v-for="(item, index) in couponList" :key="index">
                <van-divider contentPosition="center">{{ item.title }}</van-divider>
                <div v-for="(citem, cindex) in item.list" v-if="item.list.length" :key="cindex">
                    <div class="coupon_line" v-if="citem.status == 1">
                        <div class="left">
                            <div class="title">
                                <van-icon name="coupon-o" /> {{ citem.coupon_title }}
                            </div>
                            <div class="line_threshold">
                                {{ citem.threshold }}
                            </div>
                            <div class="line">
                                {{ citem.validity }}
                            </div>
                        </div>
                        <div class="right" @click="toUseCoupon(citem)">
                            {{ citem.button }}
                        </div>
                    </div>
                    <div class="coupon_line_delete" v-else>
                        <div class="left">
                            <div class="title">
                                <van-icon name="coupon-o" /> {{ citem.coupon_title }}
                            </div>
                            <div class="line_threshold">
                                {{ citem.threshold }}
                            </div>
                            <div class="line">
                                {{ citem.validity }}
                            </div>
                        </div>
                        <div class="right">
                            {{ citem.button }}
                        </div>
                    </div>
                </div>
                <van-empty v-if="!item.list.length" />
            </div>
        </div>
        <van-empty v-else />
    </div>
</template>
<script>
import { onBeforeMount, ref } from 'vue';
import request from '../../utils/request';
import { useRouter } from "vue-router";
export default {
    setup() {
        const router = useRouter();
        const onClickLeft = () => history.back();
        const couponList = ref([]);
        onBeforeMount(() => {
            loadCouponData()
        });
        const loadCouponData = () => {
            request({
                url: '/app/user/user_coupon',
                method: 'GET',
            }).then((res) => {
                if (res && res.items) {
                    couponList.value = res.items
                }
            })
        };
        const toUseCoupon = (item) => {
            if (item.product_id) {
                router.push({ name: "Cart", query: { productid: item.product_id } })
            }
        };
        return {
            onClickLeft,
            couponList,
            toUseCoupon,
        }
    },
}
</script>

<style>
.my_coupon {
    overflow: hidden;
}

.my_coupon .coupon_list {}

.my_coupon .coupon_list .coupon_box {
    padding: 10px;
}

.my_coupon .coupon_list .coupon_line {
    background-color: #FE5151;
    border-radius: 10px;
    display: flex;
    margin-bottom: 10px;
}

.my_coupon .coupon_list .coupon_line .left {
    flex: 1;
}

.my_coupon .coupon_list .coupon_line .right {
    color: #FE5151;
    padding: 25px 0px;
    text-align: center;
    background-color: #ffffff;
    border: 2rpx solid #FE5151;
    border-radius: 0rpx 10px 10px 0rpx;
    border-left: none;
    font-weight: bolder;
    line-height: 50px;
    width: 100px;
}

.my_coupon .coupon_list .coupon_line .title {
    color: #ffffff;
    font-size: 16px;
    padding: 10px;
}

.my_coupon .coupon_list .coupon_line .line {
    color: #ffffff;
    font-size: 12px;
    padding: 0rpx 10px;
    margin-top: 10px;
}

.my_coupon .coupon_list .coupon_line .line_threshold {
    color: #ffffff;
    font-size: 12px;
    padding: 0rpx 10px;
    margin-top: 10px;
}

.my_coupon .coupon_list .coupon_line_delete {
    display: flex;
    margin-bottom: 10px;
}

.my_coupon .coupon_list .coupon_line_delete .left {
    flex: 1;
    background-color: #FE5151;
    border-radius: 10px 0 0 10px;
}

.my_coupon .coupon_list .coupon_line_delete .right {
    color: #969799;
    padding: 25px 0px;
    text-align: center;
    background-color: #ebedf0;
    border: 1px solid #ebedf0;
    border-radius: 0px 10px 10px 0px;
    border-left: none;
    font-weight: bolder;
    line-height: 50px;
    width: 100px;
}

.my_coupon .coupon_list .coupon_line_delete .title {
    color: #ffffff;
    font-size: 16px;
    padding: 10px;
}

.my_coupon .coupon_list .coupon_line_delete .line {
    color: #ffffff;
    font-size: 12px;
    padding: 0px 10px;
    margin-top: 10px;
}

.my_coupon .coupon_list .coupon_line_delete .line_threshold {
    color: #ffffff;
    font-size: 12px;
    padding: 0rpx 10px;
    margin-top: 10px;
}</style>